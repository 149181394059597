
/* eslint-disable no-unused-expressions */
import { ref, defineComponent, getCurrentInstance } from 'vue'
import ListDataHjex from '@/views/shared-components/list/ListDataHjex.vue'
import rupiahFormat from '@/utils/helpers/rupiahFormat'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { TResultMsKategoriBarang } from '@/data/source/remote/api/backend/V2/msKategoriBarang-model'
import ColumActive from '@/views/shared-components/column/ColumnActive.vue'
import OverlayPanel from 'primevue/overlaypanel'
import { itemCategoryUseCase } from '@/domain/usecase'
import ItemCategoryForm from './ItemCategoryForm.vue'

const endpoint = '/backend/v2/mskategoribarangs'

export default defineComponent({
  components: {
    ListDataHjex,
    ColumActive,
    ItemCategoryForm
  },
  setup() {
    const app = getCurrentInstance()
    const { $toast, $confirm } = app!.appContext.config.globalProperties
    const store = useStore()
    const route = useRoute()
    const { permission }: any = route.meta
    const refListDataHjex = ref<InstanceType<typeof ListDataHjex>>()
    const op = ref<InstanceType<typeof OverlayPanel>>()
    const dataEdit = ref<TResultMsKategoriBarang>()
    const sliderActive = ref(false)
    const paramListData = ref({
      endpoint,
      title: 'Kategori Barang',
      showBtnAdd: true,
      labelBtnAdd: 'Tambah Kategori',
      fieldSearch: ['name']
    })

    const closeSidebar = () => {
      sliderActive.value = false
    }
    const onReload = () => {
      refListDataHjex.value?.getData()
    }

    const showDropDown = (evt: Event, item: TResultMsKategoriBarang) => {
      // eslint-disable-next-line no-unused-expressions
      op.value?.toggle(evt)
      dataEdit.value = item
    }

    const onEditData = () => {
      sliderActive.value = true
    }

    const onDelete = () => {
      $confirm.require({
        header: 'Kategori Barang',
        message: 'Apakah anda yakin ingin menghapusnya?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const {
            error,
            message
          } = await itemCategoryUseCase.delete(Number(dataEdit.value?.Id))

          if (error) {
            $toast.add({
              severity: 'error', detail: message, group: 'bc', life: 3000
            })
          } else {
            onReload()
            $toast.add({
              severity: 'success', detail: 'Berhasil dihapus', group: 'bc', life: 3000
            })
          }
          store.dispatch('hideLoading')
        }
      })
    }

    const redirectAdd = () => {
      dataEdit.value = undefined
      sliderActive.value = true
    }
    return {
      refListDataHjex,
      paramListData,
      rupiahFormat,
      permission,
      store,
      endpoint,
      op,
      showDropDown,
      dataEdit,
      onEditData,
      onDelete,
      onReload,
      sliderActive,
      closeSidebar,
      redirectAdd
    }
  },
})
