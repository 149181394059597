
/* eslint-disable vue/no-dupe-keys */
import {
  defineComponent,
  toRefs,
  ref,
  computed
} from 'vue'
import type { PropType } from 'vue'
import Multiselect from '@vueform/multiselect'
import { Remote, remoteEnum } from '@/data/source/remote/remoteApi'
import returnUrlPrams from '@/utils/helpers/returnUrlPrams'
import RepositoryV2 from '@/data/repositoryV2'
import {
  debounce
} from 'lodash'

export default defineComponent({
  name: 'MultiselectAsync',
  components: {
    Multiselect
  },
  props: {
    modelValue: {
      type: [Object, Array, String, Number],
      default: null
    },
    idInput: {
      type: String,
      default: 'idMultiselect'
    },
    placeholder: {
      type: String,
      default: 'Cari telebih dahulu.'
    },
    canClear: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String as PropType<'single' | 'multiple' | 'tags'>,
      default: 'single'
    },
    canDeselect: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    noOptionsText: {
      type: String,
      default: 'Option kosong'
    },
    noResultsText: {
      type: String,
      default: 'Tidak ada hasil ditemukan'
    },
    labelDisplay: {
      type: String,
      default: 'label'
    },
    valueProp: {
      type: String,
      default: 'value'
    },
    fieldLabel: {
      type: [String, Function],
      default: () => 'Name'
    },
    fieldValue: {
      type: String,
      default: 'Id'
    },
    searchFields: {
      type: Array as PropType<string[]>,
      default: []
    },
    endpoint: {
      type: String,
      default: '',
      require: true
    },
    limit: {
      type: Number,
      default: 10
    },
    disabled: {
      type: Boolean,
      default: false
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    // style: {
    //   type: [Object, String],
    //   default: ''
    // },
    filters: {
      type: Array as PropType<string[][]>,
      default: []
    },
    trackBy: {
      type: String
    },
    minChars: {
      type: Number,
      default: 3
    },
    filterResults: {
      type: Boolean,
      default: false
    },
    excludeOptions: {
      type: Array as PropType<string[]>,
      default: []
    }
  },
  emits: {
    'update:modelValue': null,
    change: null
  },
  setup(props, { emit }) {
    const {
      modelValue,
      idInput,
      placeholder,
      canClear,
      canDeselect,
      mode,
      searchable,
      labelDisplay,
      noOptionsText,
      noResultsText,
      valueProp,
      disabled,
      endpoint,
      fieldLabel,
      fieldValue,
      limit,
      searchFields,
      closeOnSelect,
      // style,
      filters,
      trackBy,
      minChars,
      filterResults,
      excludeOptions
    } = toRefs(props)
    const refMultiselect = ref()
    // const options = ref([])
    const isLoading = ref(false)
    const isIdle = ref(false)
    const selectedData = ref(null)
    const value = computed({
      get: () => props.modelValue,
      set: (newVal) => {
        emit('update:modelValue', newVal)
      }
    })
    const processSearch = async (search = '') => {
      isLoading.value = true
      let data = []
      const urlParams = returnUrlPrams({
        search,
        filterField: searchFields.value,
        pageSize: limit.value,
        custom: filters.value
      })
      const {
        error,
        result
      } = await new RepositoryV2(new Remote(remoteEnum.get, `${endpoint.value}${urlParams}`), null).getResult(false)
      if (!error) {
        const mapData = result.map((item: any) => ({
          ...item,
          label: typeof fieldLabel.value === 'function' ? fieldLabel.value(item) : item[fieldLabel.value],
          value: item[fieldValue.value],
          disabled: excludeOptions.value.includes(typeof fieldLabel.value === 'function' ? fieldLabel.value(item) : item[fieldLabel.value])
        }))
        data = mapData
      }
      isLoading.value = false
      return data
    }
    const options = async (query: string) => {
      let result = []
      if (isIdle.value) {
        result = await processSearch(query)
      }
      return result
    }
    const onSearchChange = debounce(processSearch, 300)
    const onChange = (value: Event, select: any) => {
      emit('change', value, select)
    }
    const onOpen = () => {
      isIdle.value = true
      if (refMultiselect.value?.noOptions) {
        // eslint-disable-next-line no-unused-expressions
        refMultiselect.value?.resolveOptions()
      }
    }
    const onClose = () => {
      isIdle.value = false
    }
    return {
      modelValue,
      options,
      isLoading,
      selectedData,
      idInput,
      canClear,
      placeholder,
      canDeselect,
      mode,
      searchable,
      labelDisplay,
      noOptionsText,
      noResultsText,
      valueProp,
      disabled,
      value,
      closeOnSelect,
      onOpen,
      onChange,
      onClose,
      onSearchChange,
      // style,
      trackBy,
      minChars,
      filterResults,
      refMultiselect
    }
  },
})
