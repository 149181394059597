/* eslint-disable vue/no-dupe-keys */
import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive, defineComponent
} from 'vue'
import type {
  PropType
} from 'vue'
import {
  itemCategoryUseCase
} from '@/domain/usecase'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers
} from '@vuelidate/validators'
import { useRoute } from 'vue-router'
import MultiselectAsync from '@/views/shared-components/select/MultiselectAsync.vue'
import { TResultMsKategoriBarang } from '@/data/source/remote/api/backend/V2/msKategoriBarang-model'
import { TObjSelect } from '@/utils/types/tOptionSelect'

const endpointGroupUser = '/identity/v2/msgroupusers'

export default defineComponent({
  components: {
    MultiselectAsync
  },
  props: {
    dataEdit: {
      type: Object as PropType<TResultMsKategoriBarang>,
      default: null
    }
  },
  emits: ['closeSidebar', 'reloadData'],
  setup(props, { emit }) {
    const store = useStore()
    const app = getCurrentInstance()
    const { $toast, $confirm } = app!.appContext.config.globalProperties
    const route = useRoute()
    const code = ref('')
    const name = ref('')
    const satuanId = ref(null)
    const min = ref(0)
    const max = ref(0)
    const isRefund = ref(false)
    const isAsurance = ref(false)
    const isActive = ref(false)
    const isCompany = ref(false)
    const dataEdit = ref(props.dataEdit)
    const listSatuan = ref([])
    const title = ref('')
    const submitted = ref(false)
    const dataForm = reactive({
      code: '',
      name: '',
      satuanId: null as number | null,
      min: 0,
      max: 0,
      description: '',
      isRefund: false,
      isAsurance: false,
      isActive: true,
      // isCompany: false,
      getDiskon: false,
      isDooring: false,
      isPriceShown: false,
      groupUser: null as TObjSelect | null
    })
    const groupUserOption = ref([])
    const changeMin = (minValue: any) => {
      if (minValue > 100) {
        dataForm.min = 100
        dataForm.max = 100
      } else if (Number(dataForm.max) < minValue) {
        dataForm.max = minValue
      }
    }
    const changeMax = (maxValue: any) => {
      if (maxValue > 100) {
        dataForm.max = 100
      }
      if (maxValue < Number(dataForm.min)) {
        dataForm.min = maxValue
      }
    }
    const rules = {
      code: {
        required: helpers.withMessage('Kode Kategori Barang harus diisi', required)
      },
      name: {
        required: helpers.withMessage('Nama Kategori Barang harus diisi', required)
      },
      satuanId: {
        required: helpers.withMessage('Satuan Berat/Ukuran harus diisi', required)
      },
      description: {
        required: helpers.withMessage('Keterangan harus diisi', required)
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const processSubmit = async () => {
      store.dispatch('showLoading')
      const data = {
        Kode: dataForm.code.toUpperCase(),
        Nama: dataForm.name,
        SatuanId: Number(dataForm.satuanId),
        IsRefund: dataForm.isRefund,
        IsAsuransi: dataForm.isAsurance,
        TambahanMin: dataForm.min,
        TambahanMax: dataForm.max,
        IsActive: dataForm.isActive,
        GetDiskon: dataForm.getDiskon,
        IsDooring: dataForm.isDooring,
        Keterangan: dataForm.description,
        IsPriceShown: dataForm.isPriceShown,
        GroupUserId: dataForm.groupUser?.value ? Number(dataForm.groupUser?.value) : null
      }
      if (Number(dataForm.min) > Number(dataForm.max)) {
        $toast.add({
          severity: 'error', detail: 'Biaya Max harus lebih besar sama dengan Biaya Min', group: 'bc', life: 3000
        })
        store.dispatch('hideLoading')
        return
      }
      const id = Number(dataEdit.value?.Id) ?? null
      const { error, message } = await itemCategoryUseCase.addOrEdit(id, data)
      submitted.value = false
      if (error) {
        $toast.add({
          severity: 'error', detail: message, group: 'bc', life: 3000
        })
      } else {
        $toast.add({
          severity: 'success', detail: 'Berhasil disimpan', group: 'bc', life: 3000
        })
        emit('reloadData')
        emit('closeSidebar')
      }
      store.dispatch('hideLoading')
    }

    const submitData = async (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      processSubmit()
    }

    const closeSidebar = () => {
      emit('closeSidebar')
    }

    const getDataForm = async () => {
      store.dispatch('showLoading')
      const {
        error,
        result
      } = await itemCategoryUseCase.getDetail(dataEdit.value?.Id)
      if (!error) {
        dataForm.code = result.Kode
        dataForm.name = result.Nama
        dataForm.min = Number(result.TambahanMin)
        dataForm.max = Number(result.TambahanMax)
        dataForm.isRefund = result.IsRefund
        dataForm.isAsurance = result.IsAsuransi
        dataForm.isActive = result.IsActive
        dataForm.getDiskon = result.GetDiskon
        dataForm.isDooring = result.IsDooring
        dataForm.description = result.Keterangan
        dataForm.isPriceShown = result.IsPriceShown
        dataForm.satuanId = result.SatuanId
        dataForm.groupUser = {
          label: result.GroupUserName,
          value: result.GroupUserId
        }
      }
      store.dispatch('hideLoading')
    }

    const deleteDataCategory = () => {
      $confirm.require({
        header: 'Kategori Barang',
        message: 'Apakah anda yakin ingin menghapusnya?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const {
            error,
            message
          } = await itemCategoryUseCase.delete(dataEdit.value.Id)

          if (error) {
            $toast.add({
              severity: 'error', detail: message, group: 'bc', life: 3000
            })
          } else {
            $toast.add({
              severity: 'success', detail: 'Berhasil dihapus', group: 'bc', life: 3000
            })
            emit('reloadData')
            emit('closeSidebar')
          }
          store.dispatch('hideLoading')
        }
      })
    }

    const showAlertRefund = (evt: any) => {
      console.log('evt', dataForm.isRefund)
      if (dataForm.isRefund) {
        $confirm.require({
          header: 'Centang Properties Refund',
          message: 'Dengan melakukan centang refund, maka kategori barang pada web agen tidak akan terpengaruh oleh batas waktu pembatalan paket di global setting.',
          // icon: 'pi pi-exclamation-triangle',
          acceptLabel: 'Lanjutkan',
          rejectLabel: 'Kembali',
          reject: () => {
            dataForm.isRefund = false
          }
        })
      }
    }

    const getSatuan = async () => {
      const {
        result,
        error
      } = await itemCategoryUseCase.getSatuan()
      if (!error) {
        listSatuan.value = result
      }
    }

    onMounted(() => {
      getSatuan()
      if (dataEdit.value && dataEdit.value.Id) {
        title.value = 'Edit Kategori Barang'
        getDataForm()
      } else {
        title.value = 'Tambah Kategori Barang'
      }
    })

    return {
      title,
      dataEdit,
      code,
      name,
      satuanId,
      min,
      max,
      isRefund,
      isAsurance,
      isActive,
      listSatuan,
      isCompany,
      v$,
      submitted,
      route,
      dataForm,
      submitData,
      closeSidebar,
      deleteDataCategory,
      changeMin,
      changeMax,
      groupUserOption,
      showAlertRefund,
      endpointGroupUser
    }
  }
})
